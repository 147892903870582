import React from 'react'
import {Switch, Route} from 'react-router-dom'
import {R} from './navigations/routes'
import SignIn from './components/pages/sign-in'
import SignUp from './components/pages/sign-up'
import GlobalStyle from './components/layout/global-style'

export default function App() {
    return (
        <>
            <GlobalStyle />
            <Switch>
                <Route exact path={R.SignIn} component={SignIn} />
                <Route exact path={R.SignUp} component={SignUp} />
            </Switch>
        </>
    )
}
