import styled from 'styled-components'

const Label = styled.span<LabelProps>`
    font-size: 1rem !important;
    color: ${(props) => props.white && 'white'};

    :hover {
        cursor: pointer;
    }
`

interface LabelProps {
    white?: boolean
}

export default Label
