import React, {useCallback, useState} from 'react'
import {FC} from 'react'
import InputMask from 'react-input-mask'
import moment, {Moment} from 'moment'
import {Col, Row} from 'antd/lib/grid'
import 'antd/lib/grid/style/css'
import Input from '../../kit/input'

const initState = {
        DD: '__',
        MM: '__',
        YYYY: '____',
    },
    valuesNotValid = (state: CommonValueState): boolean => {
        const values = Object.values(state)
        return values.some((value) => value.indexOf('_') !== -1)
    },
    getDateAsString = ({DD, MM, YYYY}: CommonValueState) => {
        return `${YYYY}-${MM}-${DD}`
    }

const ThreeInputsDate: FC<ThreeInputsDateProps> = ({
    value = null,
    onChange = () => {},
    disabled = false,
}) => {
    const [state, setState] = useState<CommonValueState>(initState),
        handleChange = useCallback(
            (id, value) => {
                // console.log('handleChange', id, value)
                const newState = {
                    ...state,
                    [id]: value || (id === 'YYYY' ? '____' : '__'),
                }
                setState(newState)

                const date = moment(getDateAsString(newState), true)

                // Если дата валидна, то изменяем значение
                if (date.isValid()) {
                    // console.log('Дата валидна, обновляем')
                    onChange(date)
                } else if (!date.isValid() && !valuesNotValid(newState)) {
                    // console.log('Дата НЕ валидна, не обновляем')
                    return
                } else {
                    // console.log('Обновляем на null')
                    onChange(null)
                }
            },
            [onChange, state]
        )

    // Для удобства форматируем дату в строку
    const stateAsString = getDateAsString(state)
    // Если новое значение является датой
    if (value) {
        const valueAsString = value.format('YYYY-MM-DD')

        if (valueAsString !== stateAsString) {
            // Если новое значение отличается от текущего состояния
            // Устанавливаем новое значение
            setState({
                DD: value.format('DD'),
                MM: value.format('MM'),
                YYYY: value.format('YYYY'),
            })
        }
    } else if (stateAsString.indexOf('_') === -1 && !value) {
        // Если новое значение пустое и дата заполнена
        setState({DD: '__', MM: '__', YYYY: '____'})
    }

    return (
        <Row gutter={8}>
            <Col xs={6} sm={6}>
                <InputMask
                    mask={'99'}
                    value={state.DD}
                    onChange={(e) => handleChange('DD', e.target.value)}
                    disabled={disabled}
                >
                    {(props: object) => <Input {...props} disabled={disabled} />}
                </InputMask>
            </Col>
            <Col xs={6} sm={6}>
                <InputMask
                    mask={'99'}
                    value={state.MM}
                    onChange={(e) => handleChange('MM', e.target.value)}
                    disabled={disabled}
                >
                    {(props: object) => <Input {...props} disabled={disabled} />}
                </InputMask>
            </Col>
            <Col xs={12} sm={12}>
                <InputMask
                    mask={'9999'}
                    value={state.YYYY}
                    onChange={(e) => handleChange('YYYY', e.target.value)}
                    disabled={disabled}
                >
                    {(props: object) => <Input {...props} disabled={disabled} />}
                </InputMask>
            </Col>
        </Row>
    )
}

interface CommonValueState {
    DD: string
    MM: string
    YYYY: string
}

interface ThreeInputsDateProps {
    value?: Moment | null
    onChange?: (value: Moment | null) => void
    disabled?: boolean
}

export default ThreeInputsDate
