import styled from 'styled-components'
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined'
import React from 'react'
import {errorColor} from '../../../constants/theme/colors'

const SimpleErrorMessage = styled.p.attrs<
    SimpleErrorMessageProps,
    SimpleErrorMessageProps
>((props) => ({
    ...props,
    children: (
        <>
            <WarningOutlined /> &nbsp;{props.children}
        </>
    ),
}))`
    display: ${(props) => (props.show ? 'block' : 'none')};
    color: ${errorColor};
    font-size: 1rem;
`

interface SimpleErrorMessageProps {
    show?: boolean
}

export default SimpleErrorMessage
