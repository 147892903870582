import styled from 'styled-components'

const P = styled.p<PProps>`
    font-size: 1rem;
    color: ${(props) => props.white && 'white'};
`

interface PProps {
    white?: boolean
}

export default P
