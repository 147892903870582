import {InlineResponse400} from '../../api/models'

const handleResponse = async (resp: Response): Promise<string> => {
    if (resp.status >= 400 && resp.status < 500) {
        // Если статус ответа "Клиентская ошибка"
        // то возможно в теле, в json пришли
        // какие нибудь пояснения

        // Смотрим в заголовки
        const contentType = resp.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
            // Значит в ответе есть json
            const data: InlineResponse400 = await resp.json()
            // Если в json нет пояснений,
            // то возвращаем стандартную ошибку
            return data.errors
                ? data.errors.join(' ')
                : `${resp.status} ${resp.statusText}`
        }
    } else if (resp.status >= 500) {
        // Если статус ответа "Серверная ошибка",
        // то вероятно инфы нам не пришло
        return `${resp.status} ${resp.statusText}`
    }
    return `Неизвестная ошибка. ${resp.status} ${resp.statusText}, ${resp.type}, ${resp.url}`
}

export default handleResponse
