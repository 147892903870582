import {FormInstance} from 'antd/lib/form'
import {FormValues} from './types'
import {InlineObject} from '../../../../api/models'
import convertMaskedPhoneToNormal from '../../../../helpers/converters/convert-masked-phone-to-normal'

const extractSignUpData = (
    form: FormInstance<FormValues>,
    recaptchaResponse: string
): InlineObject => {
    const data = form.getFieldsValue()

    return {
        birthday: data.birthday.format('YYYY-MM-DD'),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        middleName: data.middleName.trim(),
        phone: convertMaskedPhoneToNormal(data.phone),
        recaptchaResponse,
    }
}

export default extractSignUpData
