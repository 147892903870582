import * as React from 'react'
import {FC} from 'react'
import {IDS, LABELS} from './constants'
import Label from '../../../kit/label'
import {STANDARD_RULES} from '../../../../constants/forms'
import Input from '../../../kit/input'
import ThreeInputsDate from '../../../form-components/three-inputs-date'
import SimpleErrorMessage from '../../../kit/simple-error-message'
import Button from '../../../kit/button'
import Form from 'antd/lib/form'
import 'antd/lib/form/style/css'
import {RequestState} from '../../../../helpers/reducers'
import Phone from '../../../form-components/phone'
import phoneValidator from '../../../../helpers/validators/phone-validator'

const {Item} = Form

const FormItems: FC<FormItemsProps> = ({requestState, onSingUp}) => {
    const started = requestState.requestStatus === 'started'

    return (
        <>
            <Item
                name={IDS.LAST_NAME}
                label={<Label white>{LABELS.LAST_NAME}</Label>}
                rules={STANDARD_RULES}
                required
            >
                <Input disabled={started} />
            </Item>
            <Item
                name={IDS.FIRST_NAME}
                label={<Label white>{LABELS.FIRST_NAME}</Label>}
                rules={STANDARD_RULES}
                required
            >
                <Input disabled={started} />
            </Item>
            <Item
                name={IDS.MIDDLE_NAME}
                label={<Label white>{LABELS.MIDDLE_NAME}</Label>}
                rules={STANDARD_RULES}
                required
            >
                <Input disabled={started} />
            </Item>
            <Item
                name={IDS.BIRTHDAY}
                label={<Label white>{LABELS.BIRTHDAY}</Label>}
                rules={STANDARD_RULES}
                required
            >
                <ThreeInputsDate disabled={started} />
            </Item>
            <Item
                name={IDS.PHONE}
                label={<Label white>{LABELS.PHONE}</Label>}
                rules={[{validator: phoneValidator}]}
                required
            >
                <Phone disabled={started} />
            </Item>

            <SimpleErrorMessage show={requestState.requestStatus === 'error'}>
                {requestState.errorMessage}
            </SimpleErrorMessage>

            <Button onClick={onSingUp} showLoading={started} wide>
                Стать агентом ЕВРОИНС
            </Button>
        </>
    )
}

interface FormItemsProps {
    requestState: RequestState<string | null>
    onSingUp: (e: any) => void
}

export default FormItems
