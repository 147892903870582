/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountNewDocument,
    AccountNewDocumentFromJSON,
    AccountNewDocumentFromJSONTyped,
    AccountNewDocumentToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    middleName: string;
    /**
     * 
     * @type {Date}
     * @memberof InlineObject
     */
    birthday: Date;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    recaptchaResponse: string;
    /**
     * 
     * @type {AccountNewDocument}
     * @memberof InlineObject
     */
    document?: AccountNewDocument;
}

export function InlineObjectFromJSON(json: any): InlineObject {
    return InlineObjectFromJSONTyped(json, false);
}

export function InlineObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'middleName': json['middleName'],
        'birthday': json['birthday'],
        'phone': json['phone'],
        'recaptchaResponse': json['recaptchaResponse'],
        'document': !exists(json, 'document') ? undefined : AccountNewDocumentFromJSON(json['document']),
    };
}

export function InlineObjectToJSON(value?: InlineObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'middleName': value.middleName,
        'birthday': value.birthday,
        'phone': value.phone,
        'recaptchaResponse': value.recaptchaResponse,
        'document': AccountNewDocumentToJSON(value.document),
    };
}


