/**
 * Конвертирует телефоны из формы в телефоны для некоторых запросов в киас
 * убираем все НЕ-цифры + семерку
 *
 * @param phone - +7 (962) 920-82-91
 * @return string - 9629208291
 */
const convertMaskedPhoneToNormal = (phone: string): string => {
    return phone.replace(/\D/g, '').slice(1)
}

export default convertMaskedPhoneToNormal