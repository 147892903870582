import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
    //@font-face {
    //  font-family: Lato;
    //  src:  url(../../../fonts/Lato-Bold.woff2) format("woff2");
    //  font-weight: bold;
    //  font-display: swap;
    //}
    
    @font-face {
      font-family: Lato;
      src:  url(/fonts/Lato-Regular.woff2) format("woff2");
      font-weight: normal;
      font-display: swap;
    }
    
    //@font-face {
    //  font-family: Lato;
    //  src:  url(../../../fonts/Lato-Light.woff2) format("woff2");
    //  font-weight: 200;
    //  font-display: swap;
    //}
    //
    //@font-face {
    //  font-family: Lato;
    //  src:  url(../../../fonts/Lato-Italic.woff2) format("woff2");
    //  font-weight: normal;
    //  font-style: italic;
    //  font-display: swap;
    //}

    * {
      box-sizing: border-box;
    }

    html {
      font-size: 16px;
    }

    body {
      background-color: gray;
      font-family: Lato, sans-serif;
    }
    
    .grecaptcha-badge {
      display: none;
    }
`

export default GlobalStyle
