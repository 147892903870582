/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    InlineResponse400,
    InlineResponse400FromJSON,
    InlineResponse400ToJSON,
} from '../models';

export interface AccountRegisterRequest {
    inlineObject: InlineObject;
}

export interface SecurityAdminLoginSubmitRequest {
    username: string;
    password: string;
    rememberMe: SecurityAdminLoginSubmitRememberMeEnum;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async accountRegisterRaw(requestParameters: AccountRegisterRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.inlineObject === null || requestParameters.inlineObject === undefined) {
            throw new runtime.RequiredError('inlineObject','Required parameter requestParameters.inlineObject was null or undefined when calling accountRegister.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/account/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     */
    async accountRegister(inlineObject: InlineObject): Promise<InlineResponse200> {
        const response = await this.accountRegisterRaw({ inlineObject: inlineObject });
        return await response.value();
    }

    /**
     */
    async securityAdminLoginFormRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async securityAdminLoginForm(): Promise<void> {
        await this.securityAdminLoginFormRaw();
    }

    /**
     */
    async securityAdminLoginSubmitRaw(requestParameters: SecurityAdminLoginSubmitRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling securityAdminLoginSubmit.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling securityAdminLoginSubmit.');
        }

        if (requestParameters.rememberMe === null || requestParameters.rememberMe === undefined) {
            throw new runtime.RequiredError('rememberMe','Required parameter requestParameters.rememberMe was null or undefined when calling securityAdminLoginSubmit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.username !== undefined) {
            formParams.append('username', requestParameters.username as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        if (requestParameters.rememberMe !== undefined) {
            formParams.append('_remember_me', requestParameters.rememberMe as any);
        }

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     */
    async securityAdminLoginSubmit(username: string, password: string, rememberMe: SecurityAdminLoginSubmitRememberMeEnum): Promise<InlineResponse2001> {
        const response = await this.securityAdminLoginSubmitRaw({ username: username, password: password, rememberMe: rememberMe });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SecurityAdminLoginSubmitRememberMeEnum {
    On = 'on',
    Off = 'off'
}
