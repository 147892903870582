/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountNewDocumentFile,
    AccountNewDocumentFileFromJSON,
    AccountNewDocumentFileFromJSONTyped,
    AccountNewDocumentFileToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountNewDocument
 */
export interface AccountNewDocument {
    /**
     * 
     * @type {string}
     * @memberof AccountNewDocument
     */
    serial: string;
    /**
     * 
     * @type {string}
     * @memberof AccountNewDocument
     */
    number: string;
    /**
     * 
     * @type {AccountNewDocumentFile}
     * @memberof AccountNewDocument
     */
    file: AccountNewDocumentFile;
}

export function AccountNewDocumentFromJSON(json: any): AccountNewDocument {
    return AccountNewDocumentFromJSONTyped(json, false);
}

export function AccountNewDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountNewDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serial': json['serial'],
        'number': json['number'],
        'file': AccountNewDocumentFileFromJSON(json['file']),
    };
}

export function AccountNewDocumentToJSON(value?: AccountNewDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial': value.serial,
        'number': value.number,
        'file': AccountNewDocumentFileToJSON(value.file),
    };
}


