import React from 'react'
import {FC} from 'react'
import Entry from '../../layout/entry'
import SignInForm from './form'
import {R} from '../../../navigations/routes'

const SignIn: FC = () => {
    document.title = 'Авторизация'

    return (
        <Entry link={{to: R.SignUp, text: 'Регистрация'}}>
            <SignInForm />
        </Entry>
    )
}

interface SignInProps {}

export default SignIn
