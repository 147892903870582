import React from 'react'
import {FC} from 'react'
import Entry from '../../layout/entry'
import {R} from '../../../navigations/routes'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {PUBLIC_RECAPTCHA_KEY} from '../../../constants'
import SignUpForm from './form'

const SignUp: FC<SignUpProps> = () => {
    document.title = 'Регистрация'

    return (
        <Entry link={{to: R.SignIn, text: 'Перейти обратно'}}>
            <GoogleReCaptchaProvider reCaptchaKey={PUBLIC_RECAPTCHA_KEY}>
                <SignUpForm />
            </GoogleReCaptchaProvider>
        </Entry>
    )
}

interface SignUpProps {}

export default SignUp
