import {FormFields} from './types'

export const IDS: Readonly<FormFields> = {
    BIRTHDAY: 'birthday',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    MIDDLE_NAME: 'middleName',
    PHONE: 'phone',
}

export const LABELS: Readonly<FormFields> = {
    BIRTHDAY: 'Дата рождения',
    FIRST_NAME: 'Имя',
    LAST_NAME: 'Фамилия',
    MIDDLE_NAME: 'Отчество',
    PHONE: 'Номер телефона',
}
