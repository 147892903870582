import styled from 'styled-components'

const getFontSize = (tag: Tag = 'h2') => {
    switch (tag) {
        case 'h1':
            return '1.845rem'
        case 'h2':
            return '1.645rem'
        case 'h3':
            return '1.345rem'
    }
}

const Heading = styled.h2<HeadingProps>`
    margin: 0;
    padding: 1rem 0;
    line-height: 1.25;
    font-size: ${(props) => getFontSize(props.as)};
    color: ${(props) => props.white && 'white'};
    text-align: ${(props) => props.center && 'center'};
`

interface HeadingProps {
    as?: Tag
    white?: boolean
    center?: boolean
}

type Tag = 'h1' | 'h2' | 'h3'

export default Heading
