export const primaryColor = '#35347E'
export const primaryColorLight = 'hsl(241,42%,50%);'
export const secondaryColor = '#FFCC00'
export const secondaryColorLight = 'hsl(48,100%,65%);'
export const errorColor = '#ff4d4f'

export const textColor = 'rgba(0,0,0,.85)'

// export const secondaryColorWithOpacity = (opacity: number) =>
//     `rgba(255, 204, 0, ${opacity})`
