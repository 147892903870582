import {FC} from 'react'
import * as React from 'react'
import P from '../../../kit/p'
import Button from '../../../kit/button'
import {useHistory} from 'react-router-dom'
import {R} from '../../../../navigations/routes'

const SignUpSuccess: FC<SignUpSuccessProps> = ({text}) => {
    const history = useHistory()

    return (
        <>
            {/*<P white>Вы успешно зарегистрировались в личном кабинете!</P>*/}
            <P white>{text}</P>
            <Button
                onClick={() => {
                    history.push(R.SignIn)
                }}
                wide
            >
                Вход в личный кабинет
            </Button>
        </>
    )
}

interface SignUpSuccessProps {
    text: string | null
}

export default SignUpSuccess
