import React, {useCallback, useReducer} from 'react'
import {FC} from 'react'
import {useForm} from 'antd/lib/form/Form'
import Form from 'antd/lib/form'
import 'antd/lib/form/style/css'
import Heading from '../../../kit/heading'
import {FormValues} from './types'
import requestReducer, {
    initState,
    requestError,
    RequestReducer,
    requestStarted,
    requestSuccess,
} from '../../../../helpers/reducers'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import handleRequestError from '../../../../helpers/request-error-handlers'
import getInitValues from './get-init-values'
import extractSignUpData from './extract-sign-up-data'
import {DefaultApi} from '../../../../api/apis'
import FormItems from './form-items'
import SignUpSuccess from './sign-up-success'

const initValues = getInitValues()

const SignUpForm: FC<SignUpFormProps> = () => {
    const {executeRecaptcha} = useGoogleReCaptcha(),
        [form] = useForm<FormValues>(),
        [state, dispatch] = useReducer<RequestReducer<string | null>>(
            requestReducer,
            initState
        ),
        onSingUp = useCallback(
            async (e) => {
                e.preventDefault()

                try {
                    await form.validateFields()
                } catch (e) {
                    return
                }
                // 89263091994

                // https://ru.inethere.com/virtual-number/receive-free-sms/russia/1506104-79969665207
                // 496621

                try {
                    if (!executeRecaptcha) return
                    dispatch(requestStarted())
                    const token = await executeRecaptcha('login'),
                        data = extractSignUpData(form, token),
                        api = new DefaultApi(),
                        result = await api.accountRegister(data)
                    dispatch(requestSuccess(result.data))
                } catch (e) {
                    const error = await handleRequestError(e)
                    dispatch(requestError(error))
                }
            },
            [executeRecaptcha, form]
        )

    return (
        <Form form={form} layout={'vertical'} initialValues={initValues}>
            <Heading as={'h1'} white center>
                Регистрация в<br />
                личном кабинете
            </Heading>

            {state.requestStatus === 'success' ? (
                <SignUpSuccess text={state.data} />
            ) : (
                <FormItems requestState={state} onSingUp={onSingUp} />
            )}
        </Form>
    )
}

interface SignUpFormProps {}

export default SignUpForm
