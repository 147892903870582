import handleError from './handle-error'
import handleResponseWithError from './handle-response-with-error'

type RequestError = Promise<Response> | Error

const handleRequestError = async (e: RequestError): Promise<string> => {
    console.error('Error', e)

    if (!e) {
        return 'Неизвестная ошибка'
    } if ('status' in e) {
        // Если ошибка является ответом сервера
        return await handleResponseWithError(e)
    } else if ('message' in e) {
        // Если ошибка является ошибкой
        return handleError(e)
    }

    return `Неизвестная ошибка. ${JSON.stringify(e)}.`
}

export default handleRequestError