import React, {ReactNode} from 'react'
import {FC} from 'react'
import styled from 'styled-components'
import {Row, Col} from 'antd/lib/grid'
import 'antd/lib/grid/style/css'
import {primaryColor} from '../../../constants/theme/colors'
import Logo from '../../kit/logo'
import {Link} from 'react-router-dom'
import {R} from '../../../navigations/routes'
import {minHorizontalPadding} from '../../../constants/theme'

const Children = styled(Col)`
    width: 100%;
    max-width: 368px;
    padding: 0 ${minHorizontalPadding};
`

const StyledEntry = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    background-color: ${primaryColor};
    //background-color: white;
`

const Header = styled.header`
    width: 100%;
    max-width: 1500px;
    padding-top: 2.625rem;
    padding-left: ${minHorizontalPadding};
    padding-right: ${minHorizontalPadding};
`

const Footer = styled.footer`
    width: 100%;
    max-width: 686px;
    text-align: center;
    color: #fff;
    padding: 2rem ${minHorizontalPadding};
`

const MainSiteLink = styled.a`
    color: #929fe0;
    font-size: 1.125rem;

    :hover {
        color: #c0c0c0;
    }
`

const StyledLink = styled(Link)`
    color: #fff;
    font-size: 1.345rem;

    :hover {
        color: #c0c0c0;
    }
`

const Entry: FC<EntryProps> = ({link, children}) => {
    return (
        <StyledEntry>
            <Header>
                <Row justify={'space-between'} align={'middle'}>
                    <Col sm={8}>
                        <Logo />
                    </Col>
                    <Col sm={8} style={{textAlign: 'center'}}>
                        <MainSiteLink
                            href={'https://euro-ins.ru'}
                            target={'_blank'}
                        >
                            перейти на сайт ЕВРОИНС
                        </MainSiteLink>
                    </Col>
                    <Col sm={8} style={{textAlign: 'right'}}>
                        <StyledLink to={link.to}>{link.text}</StyledLink>
                    </Col>
                </Row>
            </Header>
            <Children>{children}</Children>
            <Footer>
                © 2009-2020, ООО РСО «ЕВРОИНС». Все материалы, размещенные на
                данном сайте, защищены законом об авторских правах и являются
                собственностью страховой компании. Лицензии на страхование ЦБ РФ
                СЛ № 3954, СИ № 3954, ОС № 3954-03
            </Footer>
        </StyledEntry>
    )
}

interface EntryProps {
    link: {
        to: R
        text: ReactNode
    }
}

export default Entry
