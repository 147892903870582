import * as React from 'react'
import {Component} from 'react'
import Input from '../../kit/input'
import InputMask from 'react-input-mask'

interface PhonePropsInterface {
    value?: string
    size?: string
    disabled?: boolean
    onChange?: (e: string) => void
}

interface PhoneStateInterface {
    value: string
}

class Phone extends Component<PhonePropsInterface, PhoneStateInterface> {
    constructor(props: PhonePropsInterface) {
        super(props)

        this.state = {
            value: props.value || '+7 (___) ___-__-__',
        }
    }

    static defaultProps = {
        size: 'large',
        disabled: false,
        onChange: (e: string) => {},
    }

    static getDerivedStateFromProps(nextProps: PhonePropsInterface) {
        if ('value' in nextProps) {
            return {
                value: nextProps.value || '+7 (___) ___-__-__',
            }
        }
        return null
    }

    handleChange = (e: any) => {
        // console.log('changeValue', e)
        const {value} = e.target
        if (e.type === 'change') {
            this.setState({value}, () => {
                if (this.props.onChange) this.props.onChange(value)
            })
        }
    }

    render() {
        const {disabled} = this.props,
            {value} = this.state

        return (
            <InputMask
                mask='+7 (999) 999-99-99'
                value={value}
                onChange={this.handleChange}
                disabled={disabled}
            >
                {(props: any) => (
                    <Input
                        {...props}
                        type={'tel'}
                        size={this.props.size}
                        disabled={disabled}
                    />
                )}
            </InputMask>
        )
    }
}

export default Phone
