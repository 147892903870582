import styled from 'styled-components'
import {errorColor, secondaryColor} from '../../../constants/theme/colors'

const Input = styled.input`
    width: 100%;
    height: 3rem;
    border-radius: 0.625rem;
    padding: 2px 0.825rem 0;
    border: 1px solid transparent;
    font-size: 1.125rem;

    :focus {
        outline: 0;
        border: 1px solid ${secondaryColor};
        box-shadow: 0 0 0 2px ${secondaryColor};
    }

    .ant-form-item-has-error & {
        border: 1px solid ${errorColor};
        box-shadow: 0 0 0 2px ${errorColor};
    }
`

export default Input
