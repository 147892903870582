import {FormValues} from './types'

const getInitValues = (): FormValues => {
    return {
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        birthday: null,
    }
}

export default getInitValues
