import styled from 'styled-components'
import {Link} from 'react-router-dom'
import React, {FC, useCallback, useReducer, useState} from 'react'
import {useForm} from 'antd/lib/form/Form'
import Form from 'antd/lib/form'
import 'antd/lib/form/style/css'
import {Col, Row} from 'antd/lib/grid'
import 'antd/lib/grid/style/css'
import {DefaultApi} from '../../../../api/apis'
import Button from '../../../kit/button'
import Input from '../../../kit/input'
import Password from '../../../kit/password'
import Label from '../../../kit/label'
import Heading from '../../../kit/heading'
import Checkbox from '../../../kit/checkbox'
import {IDS, LABELS} from './constants'
import requestReducer, {
    initState,
    requestError,
    RequestReducer,
    requestStarted,
    requestSuccess,
} from '../../../../helpers/reducers'
import handleRequestError from '../../../../helpers/request-error-handlers'
import SimpleErrorMessage from '../../../kit/simple-error-message'
import getInitValues from './get-init-values'
import {FormValues} from './types'
import {STANDARD_RULES} from '../../../../constants/forms'
import extractSignInData from './extract-sign-in-data'
import {InlineResponse2001Data} from '../../../../api/models'
import Phone from '../../../form-components/phone'
import phoneValidator from '../../../../helpers/validators/phone-validator'
import convertMaskedPhoneToNormal from '../../../../helpers/converters/convert-masked-phone-to-normal'
import {R} from '../../../../navigations/routes'

const {Item} = Form

const LoginTypeLabel = styled.span<{isActive: boolean}>`
    border-bottom: 2px dashed
        ${(props) => (props.isActive ? 'white' : 'transparent')};
    padding-bottom: 1px;
`

const initValues = getInitValues()

enum LoginItemTypeEnum {
    Phone,
    Login,
}

type Reducer = RequestReducer<InlineResponse2001Data | null>

const SignInForm: FC<SignInFormProps> = () => {
    // const [loginItemType, setLoginItemType] = useState<LoginItemTypeEnum>(
    //         LoginItemTypeEnum.Login
    //     )
    const [form] = useForm<FormValues>(),
        [state, dispatch] = useReducer<Reducer>(requestReducer, initState),
        onSignIn = useCallback(
            async (e) => {
                e.preventDefault()

                try {
                    // Проверка полей формы
                    await form.validateFields()
                } catch (e) {
                    return
                }

                try {
                    dispatch(requestStarted())
                    const data = extractSignInData(form),
                        api = new DefaultApi(),
                        // login =
                        //     loginItemType === LoginItemTypeEnum.Phone
                        //         ? convertMaskedPhoneToNormal(data.username)
                        //         : data.username.trim(),
                        login = data.username.trim(),
                        result = await api.securityAdminLoginSubmit(
                            login,
                            data.password,
                            data._remember_me
                        )
                    dispatch(requestSuccess(result.data))
                    window.location.pathname = result.data.redirectUrl
                } catch (e) {
                    console.error('Error', e)
                    const error = await handleRequestError(e)
                    dispatch(requestError(error))
                }
            },
            // [form, loginItemType]
            [form]
        ),
        started = state.requestStatus === 'started'

    // const label = (
    //     <Label white>
    //         <LoginTypeLabel
    //             isActive={loginItemType === LoginItemTypeEnum.Login}
    //             onClick={() => setLoginItemType(LoginItemTypeEnum.Login)}
    //         >
    //             Логин
    //         </LoginTypeLabel>{' '}
    //         /{' '}
    //         <LoginTypeLabel
    //             isActive={loginItemType === LoginItemTypeEnum.Phone}
    //             onClick={() => setLoginItemType(LoginItemTypeEnum.Phone)}
    //         >
    //             Телефон
    //         </LoginTypeLabel>
    //     </Label>
    // )

    // const LoginItems = {
    //     [LoginItemTypeEnum.Phone]: (
    //         <Item
    //             name={IDS.LOGIN}
    //             label={label}
    //             required
    //             rules={[{validator: phoneValidator}]}
    //         >
    //             <Phone disabled={started} />
    //         </Item>
    //     ),
    //     [LoginItemTypeEnum.Login]: (
    //         <Item
    //             name={IDS.LOGIN}
    //             label={label}
    //             required
    //             rules={STANDARD_RULES}
    //         >
    //             <Input disabled={started} />
    //         </Item>
    //     ),
    // }

    // @ts-ignore
    window.form = form

    return (
        <Form form={form} layout={'vertical'} initialValues={initValues}>
            <Heading as={'h1'} white center>
                Авторизация в<br />
                личном кабинете
            </Heading>

            {/* {LoginItems[loginItemType]} */}
            <Item
                name={IDS.LOGIN}
                label={<Label white>Логин</Label>}
                required
                rules={STANDARD_RULES}
            >
                <Input disabled={started} />
            </Item>

            <Item
                name={IDS.PASSWORD}
                label={<Label white>{LABELS.PASSWORD}</Label>}
                required
                rules={STANDARD_RULES}
            >
                <Password />
            </Item>
            <Row justify={'space-between'}>
                <Col>
                    <Item
                        name={IDS.REMEMBER_ME}
                        valuePropName={'checked'}
                        required
                    >
                        <Checkbox>{LABELS.REMEMBER_ME}</Checkbox>
                    </Item>
                </Col>
                <Col sm={24}>
                    <SimpleErrorMessage show={state.requestStatus === 'error'}>
                        {state.errorMessage}
                    </SimpleErrorMessage>
                </Col>
            </Row>
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col sm={24}>
                    <Button onClick={onSignIn} showLoading={started} wide>
                        Войти в кабинет
                    </Button>
                </Col>
                <Col sm={24}>
                    <Link to={R.SignUp}>
                        <Button wide>Стать агентом</Button>
                    </Link>
                </Col>
            </Row>
        </Form>
    )
}

interface SignInFormProps {}

export default SignInForm

/*
<Col>
<Link
to={R.ForgotPassword}
style={{marginTop: 4, display: 'inline-block'}}
white
>
Забыли пароль?
</Link>
</Col>
 */
