import React from 'react'
import {FC} from 'react'
import styled from 'styled-components'
import {secondaryColor} from '../../../constants/theme/colors'
import Label from '../label'

const width = '1.375rem'

const CheckboxCont = styled.div`
    display: inline-block;
    position: relative;
    width: ${width};
    height: ${width};
    vertical-align: middle;
`

const SelfCheckbox = styled.input.attrs({type: 'checkbox'})`
    position: absolute;
    width: ${width} !important;
    height: ${width} !important;
    padding: 0;
    opacity: 0;
    z-index: 1;
    background-color: red;
    border: 4px solid red;

    :hover {
        cursor: pointer;
    }
`

const StyledCheckbox = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    width: ${width};
    height: ${width};
    border-radius: 0.25rem;
    border: 1px solid transparent;
    background-color: white;
    overflow: hidden;

    ${SelfCheckbox}:checked + & {
        background-color: ${secondaryColor};
    }

    ${SelfCheckbox}:focus + & {
        border: 1px solid ${secondaryColor};
        box-shadow: 0 0 0 1px ${secondaryColor};
    }
`

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 3px;
`

const LabelText = styled.span`
    padding: 0 4px;
`

const Checkbox: FC<CheckboxProps> = ({
    className,
    onChange = () => {},
    checked = false,
    children,
    ...props
}) => {
    const checkbox = (
        <CheckboxCont className={className}>
            <SelfCheckbox
                checked={checked}
                onChange={(e: any) => {
                    onChange(e.target.checked)
                }}
                {...props}
            />
            <StyledCheckbox>
                <Icon>
                    <polyline points='17 5 8 15 4 12' />
                </Icon>
            </StyledCheckbox>
        </CheckboxCont>
    )

    if (children) {
        return (
            <Label as={'label'} white>
                {checkbox} <LabelText>{children}</LabelText>
            </Label>
        )
    }

    return checkbox
}

interface CheckboxProps {
    className?: string
    checked?: boolean
    onChange?: (value: boolean) => void
}

export default Checkbox
