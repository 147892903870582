import {FormValues} from './types'

const getInitValues = (): FormValues => {
    // if ()

    return {
        password: '',
        login: '',
        rememberMe: false,
    }
}

export default getInitValues
