import React from 'react'
import styled from 'styled-components'
import {
    primaryColor,
    primaryColorLight,
    secondaryColor,
    secondaryColorLight, textColor,
} from '../../../constants/theme/colors'
import {ButtonHTMLAttributes} from 'react'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'

const Button = styled.button.attrs<ButtonProps, ButtonProps>(
    ({showLoading, onClick, children, ...props}) => {
        console.log('props', props)
        return {
            showLoading: showLoading,
            onClick: showLoading ? () => {} : onClick,
            children: showLoading ? (
                <>
                    <LoadingOutlined /> {children}
                </>
            ) : (
                children
            ),
            ...props,
        }
    }
)`
    position: relative;
    width: ${(props) => (props.wide ? '100%' : 'auto')};
    height: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: ${(props) =>
        props.kind === 'primary' ? primaryColor : secondaryColor};
    border: 0;
    font-size: 1.375rem;
    border-radius: 0.625rem;
    cursor: pointer;
    color: ${textColor};

    :hover {
        background-color: ${(props) =>
            props.kind === 'primary' ? primaryColorLight : secondaryColorLight};
    }

    :focus {
        outline: none;
        background-color: ${(props) =>
            props.kind === 'primary' ? primaryColorLight : secondaryColorLight};
    }

    :before {
        position: absolute;
        display: ${(props) => (props.showLoading ? 'block' : 'none')};
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        background-color: #fff;
        opacity: 0.35;
        transition: opacity 0.2s;
        z-index: 1;
        cursor: initial;
    }
`

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    kind?: 'primary' | 'secondary'
    wide?: boolean
    showLoading?: boolean
}

// const Button: FC<ButtonProps> = ({showLoading = false, onClick, children, ...rest}) => {
//     const handleClick = showLoading ? () => {} : onClick
//
//     return <StyledButton showLoading={showLoading} onClick={handleClick} {...rest}>
//         {showLoading && <LoadingOutlined />} {children}
//     </StyledButton>
// }

export default Button
