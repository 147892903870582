import {Reducer} from 'react'

export type RequestStatus = 'didNotLoad' | 'started' | 'success' | 'error'

export const initState: RequestState<null> = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null
}

export enum ActionTypes {
    Started,
    Success,
    Error
}

const requestReducer = <T>(state: RequestState<T>, action: Action<T>): RequestState<T> => {
    switch (action.type) {
        case ActionTypes.Started:
            return {
                requestStatus: 'started',
                errorMessage: '',
                data: null
            }
        case ActionTypes.Success:
            return  {
                requestStatus: 'success',
                errorMessage:  '',
                data: action.payload
            }
        case ActionTypes.Error:
            return  {
                requestStatus: 'error',
                errorMessage:  action.payload,
                data: null
            }
        default: return state
    }
}

export type RequestReducer<T> = Reducer<RequestState<T>, Action<T>>

export interface RequestState<T> {
    requestStatus: RequestStatus
    errorMessage: string
    data: T | null
}

type Action<T> = StartedAction | SuccessAction<T> | ErrorAction

interface StartedAction {
    type: ActionTypes.Started
}

interface SuccessAction<T> {
    type: ActionTypes.Success
    payload: T
}

interface ErrorAction {
    type: ActionTypes.Error
    payload: string
}

export const requestStarted = (): StartedAction => {
    return {type: ActionTypes.Started}
}

export const requestSuccess = <T>(payload: T): SuccessAction<T> => {
    return {type: ActionTypes.Success, payload}
}

export const requestError = (payload: string): ErrorAction => {
    return {type: ActionTypes.Error, payload}
}

export default requestReducer