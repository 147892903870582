import {FormInstance} from 'antd/lib/form'
import {FormValues} from './types'
import {SecurityAdminLoginSubmitRememberMeEnum} from '../../../../api/apis'

interface SignInData {
    username: string
    password: string
    _remember_me: SecurityAdminLoginSubmitRememberMeEnum
}

const extractSignInData = (form: FormInstance<FormValues>): SignInData => {
    const data = form.getFieldsValue()
    return {
        username: data.login.trim(), // .replace(/\D/g, '').slice(1),
        password: data.password.trim(),
        _remember_me: data.rememberMe
            ? SecurityAdminLoginSubmitRememberMeEnum.On
            : SecurityAdminLoginSubmitRememberMeEnum.Off,
        // /admin/login
    }
}

export default extractSignInData
