import {FormFields} from './types'

export const IDS: Readonly<FormFields> = {
    LOGIN: 'login',
    PASSWORD: 'password',
    REMEMBER_ME: 'rememberMe',
}

export const LABELS: Readonly<FormFields> = {
    LOGIN: 'Логин (телефон)',
    PASSWORD: 'Пароль',
    REMEMBER_ME: 'Запомнить меня',
}

export const SIGN_IN_FORM_DATA = 'SignInFormData'
